<template>
  <span class="tooltiptextcard hidden lg:block">
    <UiLanguagePlaceholder v-show="!props.status" domain="page.merkzettel" name="heart_tooltip" />
    <UiLanguagePlaceholder v-show="props.status" domain="page.merkzettel" name="heart_tooltip_active" />
  </span>
</template>

<script setup lang="ts">
const props = defineProps({
  status: {
    type: Boolean,
    default: false
  }
})
</script>
<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext{
  visibility: hidden;
  background-color: black;
  color: #fff;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: 50%;
  width: 170px;
  margin-left: -60px;
}

.tooltipcard .tooltiptextcard{
  visibility: hidden;
  background-color: black;
  color: #fff;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  width: 170px;
  margin-left: -120px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltipcard .tooltiptextcard::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left:  30px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltipcard:hover .tooltiptextcard {
  visibility: visible;
}
</style>
