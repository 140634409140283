const useObjectHelper = () => {
  const isNewObject = (freigeschaltetSeit: string): boolean => {
    const currentDate = new Date()
    const freigeschaltetDate = new Date(freigeschaltetSeit)
    const monthsDifference = (currentDate.getFullYear() - freigeschaltetDate.getFullYear()) * 12 + (currentDate.getMonth() - freigeschaltetDate.getMonth())
    return monthsDifference <= 4
  }

  return {
    isNewObject
  }
}

// export { useObjectHelper }
export default useObjectHelper
